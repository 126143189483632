import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from '../components/templates/BaseLayout';
import Headline from '../components/atoms/typography/Headline';
import Paragraph from '../components/atoms/typography/Paragraph';
import Spacer from '../components/atoms/utility/Spacer';
import DownloadAppCTA from '../components/atoms/callouts/DownloadAppCTA';
import SEO from '../components/atoms/utility/seo';
import {
  APPLE_STORE_LINK,
  GOOGLE_PLAYSTORE_LINK
} from '../utils/helpers/download-link-urls';

export default function MobileLink({ location, data: { phoneDemoImg } }) {
  const redirectToMobileApp = async () => {
    try {
      const { userAgent } = navigator;
      let url = '/';

      if (
        userAgent.includes('iPhone') ||
        userAgent.includes('iPad') ||
        userAgent.includes('iPod')
      ) {
        url = APPLE_STORE_LINK;
      } else if (userAgent.includes('Android')) {
        url = GOOGLE_PLAYSTORE_LINK;
      }

      // Define the mapping of keywords to their corresponding prefixes
      const pathMap = {
        activity: 'activities',
        community: 'communities',
        business: 'business',
        // Add more mappings as needed
      };

      // Split the URL query parameters by '=' to extract the ID value
      const locationParamsArray = location?.search.split('=');

      // Check if there are exactly two parts after splitting (key and value)
      if (locationParamsArray.length === 2) {
        // Find the matching path for the URL keyword
        const keyword = Object.keys(pathMap).find((key) =>
          location.href.includes(key)
        );

        // If a matching keyword is found, construct the new URL and redirect
        if (keyword) {
          const path = pathMap[keyword];
          const mobileUrl = `flockx://${path}/${locationParamsArray[1]}`;
          window.location.replace(mobileUrl);
        }
      }
    } catch (err) {
      console.log(
        'Error in redirectToMobileApp: ',
        JSON.stringify(err, null, 2)
      );
    }
    return null;
  };

  useEffect(() => {
    if (location?.search) redirectToMobileApp();
  }, [location?.search]);

  return (
    <Layout>
      <SEO
        title='Download and Experience AI Agent Collaboration'
        description='Download the flockx AI Agent App to manage your AI Agent, kickoff collaborations with others, and discover new insights and actions your Agent can tap into.'
        customSeoImage={phoneDemoImg}
      />
      <div className='grid grid-cols-1 lg:grid-cols-12 gap-4 items-center justify-center py-10 lg:py-20'>
        <div className='col-span-6 order-2 lg:order-1'>
          <div className='xl:ml-24 w-full flex lg:block items-center justify-center '>
            <GatsbyImage
              image={getImage(phoneDemoImg)}
              alt='A model of the new flockx mobile application.'
            />
          </div>
        </div>
        <div className='col-span-6 mx-auto lg:pl-20 order-1 lg:order-2'>
          <Headline
            headerText=''
            className='text-center lg:text-left mb-5 lg:mb-10 lg:text-[64px]'
            spanColor='#D965FE'
            postSpanText='Eliminate FOMO'
          />
          <Paragraph
            paragraphText='Download the flockx AI Agent App to manage your AI Agent, kickoff collaborations with others, and discover new insights and actions your Agent can tap into.'
            className='text-center lg:text-left max-w-3xl mx-auto'
          />
          <DownloadAppCTA
            containerStyles='mx-auto w-full flex items-center lg:justify-start justify-center mt-8 lg:my-8'
            id='mobile-link'
          />
          <Spacer className='lg:hidden' />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
    query MobilelinkRedirectPageQuery {
        phoneDemoImg: file(name: { eq: "mobile-link-meta-data-image" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, width: 445)
            }
        }
    }
`;
